import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { StatsService } from '../../../../core/http';
import { Stats } from '../../../../shared/models';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  stats$: Observable<Stats>;

  constructor(
    private statsService: StatsService,
    private flashService: FlashMessagesService
  ) { }

  ngOnInit(): void {
    this.stats$ = this.statsService.getStats()
      .pipe(
        catchError(() => {
          this.flashService.show('Impossible de récupérer les stats actuellement', { cssClass: 'alert-danger'});
          return of(null);
        })
      );
  }

}
