import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';


/**
 * Pass untouched request through to the next request handler.
 * Used to handle CAS authentication (401 will redirect to CAS login form or refresh the session)
 */
@Injectable()
export class CasInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const csrf = this.getCookie('csrftoken');
    if (csrf) {
      req = req.clone({ headers: req.headers.set('X-CSRFToken', csrf)} );
    }
    return next.handle(req)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  /**
   * Handle redirects following the Http status
   * @param err: The httpErrorResponse
   */
  handleError(err: HttpErrorResponse): Observable<any> {
    /** Website on maintenance */
    if (err.status === 503) {
      this.router.navigate([`/${err.status}`]);
    }
    /** Not authenticated or Session expired, redirect to login page */
    if (err.status === 401) {
      window.location.href = `${environment.baseUrl}/account/login`;
    }

    return throwError(err);
  }

  getCookie(name: string): string {
    const parts: string[] = `; ${document.cookie}`.split(`; ${name}=`);
    if (parts && Array.isArray(parts) && parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return '';
  }
}
