import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Auth } from '../../shared/models';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = `${environment.baseUrl}/auth`;
  private httpOptions = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  private currentUser: Auth;

  constructor(
    private http: HttpClient,
  ) { }

  public hasAccess(forceCall: boolean = false): Observable<boolean> {
    return (!this.currentUser || forceCall)
      ? this.http.get(`${this.url}`, this.httpOptions).pipe(
        tap((userAuth: Auth) => this.currentUser = userAuth),
        map(() =>  this.currentUser.hasAccess)
      )
      : of (this.currentUser.hasAccess);
  }
}
