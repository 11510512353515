import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Scavenger } from '@wishtack/rx-scavenger';

import { User } from '../../../../../shared/models';
import { UserService, GroupService } from '../../../../../core/http';
import errorMessages from '../../../../../core/helpers/messages';



@Component({
  selector: 'app-user-delete',
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss']
})
export class UserDeleteComponent implements OnInit, OnDestroy {
  private scavenger = new Scavenger(this);
  readonly isDelete = this.router.url.includes('/user/delete');
  user: User;
  user$: Observable<User>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private groupService: GroupService,
    private flashMessageService: FlashMessagesService
  ) { }

  ngOnInit(): void {
    this.user$ = this.userService.getUser(+this.route.snapshot.paramMap.get('id'))
      .pipe(
        tap((user: User) => this.user = user),
        catchError(() => this.handleHttpError())
      );
  }

  delete(): void {
     if (!confirm('Etes vous sûr de vouloir supprimer cet utilisateur ?')) { return; }
     this.userService.deleteUser(this.user)
       .pipe(
         this.scavenger.collectByKey('deleteUser'),
         tap(() => {
           this.flashMessageService.show('L\'utilisateur a bien été supprimé', { cssClass: 'alert-success'});
           this.router.navigate(['/users']);
         }),
         catchError(() => this.handleHttpError())
       )
       .subscribe();
  }

  ngOnDestroy(): void {}

  private handleHttpError(): Observable<any> {
    this.flashMessageService.show(errorMessages.HTTP_500_SERVER_ERROR, { cssClass: 'alert-danger'});
    this.router.navigate(['/users']);
    return of(null);
  }
}
