import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

import { Stats } from '../../shared/models';


@Injectable({
  providedIn: 'root'
})
export class StatsService {
  private url = `${environment.baseUrl}/stats`;
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json')
  };

  constructor(
    private http: HttpClient,
  ) { }

  getStats(): Observable<Stats> {
    return this.http.get(this.url, this.httpOptions) as Observable<Stats>;
  }
}
