import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthService } from '../http';

@Injectable()
export class GuardComponent implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  /**
   * Handle user permission for the current Url
   * @param route The current url under the GuardComponent
   * @returns returns a Observable<boolean> if the user has access or redirect to error page
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
    const forceCall: boolean = route.data.force as boolean; // Force the authService call
    return this.authService.hasAccess(forceCall)
      .pipe(
        tap((access: boolean) =>  !access ? this.router.navigate(['/403']) : null),
        catchError(err =>  this.handleError(err))
      );
  }

  /**
   * Redirects following the http status
   * @param error HttpErrorResponse (containing the Http status)
   */
  handleError(error: HttpErrorResponse): Observable<any> {
    if ([503, 500, 403].includes(error.status)) {
        this.router.navigate([`/${error.status}`]);
    }
    return throwError(error);
  }
}
