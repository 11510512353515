import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap';
import { Subject} from 'rxjs';
import { Scavenger } from '@wishtack/rx-scavenger';
import { tap } from 'rxjs/operators';

import { Page, Group } from '../../../../../shared/models';
import { GroupService } from '../../../../../core/http';
import { ColumnSortEvent } from '../../../../../shared/components/column-sort/column-sort-event.model';


@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit, OnDestroy {
  private scavenger = new Scavenger(this);
  isReady = false;
  isLoading = false;
  page = new Page(10, 1, 0);
  searchValue = '';
  changingValue: Subject<string> = new Subject();
  sort: { field: string, order: number } = { field: null, order: 0 };
  groups: Group[];

  constructor(
    private groupService: GroupService
  ) { }

  ngOnInit(): void {
    this.loadGroups();
  }

  loadGroups(): void {
    this.isLoading = true;
    this.groupService.getPagedGroups(this.searchValue, this.page, this.sort.field, this.sort.order)
      .pipe(
        this.scavenger.collectByKey('loadGroups'),
        tap((result: {groups: Group[], totalElements: number}) => {
          this.page.totalElements = result.totalElements;
          this.groups = result.groups;
          this.isReady = true;
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  handleSearch(value: string): void {
    this.searchValue = value;
    this.loadGroups();
  }

  handleOrderChange($event: ColumnSortEvent): void {
    this.sort = { order: $event.order, field: $event.field };
    this.loadGroups();
  }

  handlePageChanged($event: PageChangedEvent): void {
    this.page.pageNumber = $event.page;
    this.loadGroups();
  }

  identify(index, group: Group): number {
    return group.id;
  }

  ngOnDestroy(): void {  }
}
