export class Page {
  size: number;
  totalElements: number;
  pageNumber: number;
  constructor(size = 20, pageNumber = 1,  totalElements = 0) {
    this.size = size;
    this.pageNumber = pageNumber;
    this.totalElements = totalElements;
  }
}
