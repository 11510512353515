import {  Group } from './group.model';

export class User {
  id: number;
  ulgId: string;
  email: string;
  firstName: string;
  lastName: string;
  group: Group;
}
