import { Component, OnDestroy, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Scavenger } from '@wishtack/rx-scavenger';

import { GroupService } from '../../../../../core/http';
import { Group } from '../../../../../shared/models';
import errorMessages from '../../../../../core/helpers/messages';


@Component({
  selector: 'app-group-delete',
  templateUrl: './group-delete.component.html',
  styleUrls: ['./group-delete.component.scss']
})
export class GroupDeleteComponent implements OnInit, OnDestroy {
  private scavenger = new Scavenger(this);
  readonly isDelete = this.router.url.includes('/group/delete');
  hasError = false;
  group$: Observable<Group>;
  group: Group;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private groupService: GroupService,
    private flashMessageService: FlashMessagesService
  ) { }

  ngOnInit(): void {
    this.group$ = this.groupService.getGroup(+this.route.snapshot.paramMap.get('id'))
      .pipe(
        map((group: Group) => this.group = group),
        catchError((err: HttpErrorResponse) => this.handleHttpError(err))
      );
  }

  delete(): void {
    this.hasError = false;
    if (!confirm('Etes vous sûr de vouloir supprimer ce groupe ? \nCela aura de nombreuses impacts sur NetIdentity.')) { return; }

    this.groupService.deleteGroup(this.group)
      .pipe(
        this.scavenger.collectByKey('deleteGroup'),
        map(() => {
          this.flashMessageService.show('Le groupe a bien été supprimé', { cssClass: 'alert-success'});
          this.router.navigate(['/groups']);
        }),
        catchError((err: HttpErrorResponse) => this.handleHttpError(err))
      )
      .subscribe();
  }

  ngOnDestroy(): void { }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
     if (err.status === 403) {
      this.hasError = true;
     } else {
       this.flashMessageService.show(errorMessages.HTTP_500_SERVER_ERROR, {cssClass: 'alert-danger'});
       this.router.navigate(['/groups']);
     }
     return throwError(err);
  }
}
