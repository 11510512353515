import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GuardComponent } from './core/guard';

import { SecureComponent } from './views/layouts/secure/secure.component';
import { UserComponent } from './views/pages/secure/user/user/user.component';
import { UserEditComponent } from './views/pages/secure/user/user-edit/user-edit.component';
import { UserDeleteComponent } from './views/pages/secure/user/user-delete/user-delete.component';
import { GroupComponent } from './views/pages/secure/group/group/group.component';
import { GroupEditComponent } from './views/pages/secure/group/group-edit/group-edit.component';
import { GroupDeleteComponent } from './views/pages/secure/group/group-delete/group-delete.component';
import { HomeComponent } from './views/pages/secure/home/home.component';
import { Page403Component } from './views/pages/errors/page403/page403.component';
import { Page500Component } from './views/pages/errors/page500/page500.component';
import { Page404Component } from './views/pages/errors/page404/page404.component';
import { Page503Component } from './views/pages/errors/page503/page503.component';

const routes: Routes = [
    { path: '', component: SecureComponent,  canActivate: [GuardComponent], children: [
        { path: '', component: HomeComponent, pathMatch: 'full' },
        { path: 'users', component: UserComponent },
        { path: 'user/add', component: UserEditComponent },
        { path: 'user/edit/:id', component: UserEditComponent },
        { path: 'user/details/:id', component: UserDeleteComponent },
        { path: 'user/delete/:id', component: UserDeleteComponent },

        { path: 'groups', component: GroupComponent },
        { path: 'group/add', component: GroupEditComponent },
        { path: 'group/edit/:id', component: GroupEditComponent },
        { path: 'group/details/:id', component: GroupDeleteComponent },
        { path: 'group/delete/:id', component: GroupDeleteComponent }
  ]},
  { path: '403', component: Page403Component },
  { path: '500', component: Page500Component },
  { path: '503', component: Page503Component },
  { path: '**', component: Page404Component }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
