import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Scavenger } from '@wishtack/rx-scavenger';

import { GroupService } from '../../../../../core/http';
import { Group } from '../../../../../shared/models';
import errorMessages from '../../../../../core/helpers/messages';


@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit, OnDestroy {
  private scavenger = new Scavenger(this);
  readonly isUpdate = this.route.snapshot.paramMap.get('id') !== null;
  isReady = false;
  isSubmitted = false;
  hasError = false;
  group: Group;
  formErrors = { title: '',  messages: [] };
  editForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private groupService: GroupService,
    private flashMessageService: FlashMessagesService
  ) { }

  ngOnInit(): void {
    if (this.isUpdate) {
      this.groupService.getGroup(+this.route.snapshot.paramMap.get('id'))
        .pipe(
          this.scavenger.collectByKey('getGroup'),
          map((group: Group)  => {
            this.group = group;
            this.getForm();
            this.isReady = true;
          }),
          catchError((err: HttpErrorResponse) => this.handleHttpError(err))
        )
        .subscribe();
    } else {
      this.group = new Group();
      this.getForm();
      this.isReady = true;
    }
  }

  onSubmit(): void {
    this.hasError = false;
    this.isSubmitted = true;
    if (this.editForm.invalid) {
      this.handleError(['Le formulaire est erroné']);
      return;
    }

    this.group = {...this.group, name: this.editForm.get('name').value.trim(), email: this.editForm.get('email').value.trim()};
    this.isUpdate ? this.updateGroup() : this.addGroup();
  }

  addGroup(): void {
    this.groupService.addGroup(this.group)
      .pipe(
        this.scavenger.collectByKey('addGroup'),
        tap(() => {
          this.flashMessageService.show('Le groupe a bien été crée', { cssClass: 'alert-success'});
          this.router.navigate(['/groups']);
        }),
        catchError((err: HttpErrorResponse) => this.handleHttpError(err))
      )
      .subscribe();
  }

  updateGroup(): void {
    this.groupService.editGroup(this.group)
      .pipe(
        this.scavenger.collectByKey('editGroup'),
        tap(() => {
          this.flashMessageService.show('Le groupe a bien été modifié.', { cssClass: 'alert-success'});
          this.router.navigate(['/groups']);
        }),
        catchError((err: HttpErrorResponse) => this.handleHttpError(err))
      )
      .subscribe();
  }

  getForm(): void {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    this.editForm = this.formBuilder.group({
      email: new FormControl(this.group.email || '', Validators.pattern(emailRegex)),
      name: new FormControl(this.group.name || '' , [Validators.required,  Validators.minLength(3)])
    });
  }

  get f() { return this.editForm.controls; }

  ngOnDestroy(): void {}

  private handleError(errors: string[], title = 'Erreur', dismissOnTimeout = true): void {
    this.formErrors.messages = [];
    this.formErrors.messages = errors;
    this.formErrors.title = title;
    this.hasError = true;
    if (dismissOnTimeout) {
      setTimeout(() => this.hasError = false, 2000);
    }
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 500) {
      this.flashMessageService.show(errorMessages.HTTP_500_SERVER_ERROR, { cssClass: 'alert-danger'});
      this.router.navigate(['/groups']);
    } else if (err.status === 400) {
      const errors = [];
      for (const key of Object.keys(err.error)) {
        errors.push(`${key} : ${err.error[key]}`);
      }
      this.handleError(errors, 'Erreur',  false);
    } else {
      this.handleError([errorMessages.HTTP_404_NOT_FOUND], 'Erreur', false);
    }
    return throwError(err);
  }
}
