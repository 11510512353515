import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page403',
  templateUrl: './page403.component.html',
  styleUrls: ['./page403.component.scss']
})
export class Page403Component implements OnInit {
  ngOnInit() {
    setTimeout(() => window.location.href = 'https://www.uliege.be', 2500);
  }
}
