import { NgModule } from '@angular/core';
import { ColumnSortComponent } from './column-sort.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ ColumnSortComponent ],
  imports: [ CommonModule ],
  exports: [ ColumnSortComponent ]
})
export class ColumnSortModule { }
