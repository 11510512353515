import { Component } from '@angular/core';
import { fadeAnimation } from '../../../core/helpers';


@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss'],
  animations: [fadeAnimation]
})
export class SecureComponent {
  isCollapsed = true;

  constructor() {  }
}
