import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Scavenger } from '@wishtack/rx-scavenger';

import { Group, User } from '../../../../../shared/models';
import { UserService, GroupService } from '../../../../../core/http';
import errorMessages from '../../../../../core/helpers/messages';



@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy {
  private scavenger = new Scavenger(this);
  readonly isUpdate = this.route.snapshot.paramMap.get('id') !== null;

  isReady = false;
  isSubmitted = false;
  hasError = false;
  groups: Group[];
  user: User;
  formErrors = { title: '',  messages: [] };
  editForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private groupService: GroupService,
    private flashMessageService: FlashMessagesService
  ) { }

  ngOnInit(): void {
    const groups$ = this.groupService.getGroups()
      .pipe(
        this.scavenger.collectByKey('getGroups'),
        tap((groups: Group[]) => this.groups = groups),
        catchError((err: HttpErrorResponse) => this.handleHttpError(err))
      );

    if (this.isUpdate) {
      groups$
        .pipe(
          mergeMap(() =>  this.userService.getUser(+this.route.snapshot.paramMap.get('id'))),
          tap((user: User) => {
            this.user = user;
            this.groups.map((group) => group.id === this.user.group.id ? this.user.group = group : null);
            this.getForm();
            this.isReady = true;
          }),
          catchError((err: HttpErrorResponse) => this.handleHttpError(err))
        )
        .subscribe();

    } else {
      groups$
        .pipe(
          tap(() => {
            this.user = new User();
            this.user.group = this.groups[0];
            this.getForm();
            this.isReady = true;
          })
        )
        .subscribe();
    }
  }

  onSubmit(): void {
    this.hasError = false;
    this.isSubmitted = true;
    if (this.editForm.invalid) {
      this.handleError(['Le formulaire est erroné']);
      return;
    }

    this.user = {  ...this.user,
      firstName: this.editForm.get('firstName').value.trim(),
      lastName: this.editForm.get('lastName').value.trim(),
      email: this.editForm.get('email').value.trim(),
      ulgId: this.editForm.get('ulgId').value.trim(),
      group: this.getGroupById(+this.editForm.get('group').value)
    };

    this.isUpdate ? this.updateUser() : this.addUser();
  }

  get f() { return this.editForm.controls; }

  ngOnDestroy(): void { }

  private addUser(): void {
    this.userService.createUser(this.user)
      .pipe(
        this.scavenger.collectByKey('createUser'),
        tap(() => {
          this.flashMessageService.show('L\'utilisateur a bien été crée', { cssClass: 'alert-success'});
          this.router.navigate(['/users']);
        }),
        catchError((err: HttpErrorResponse) => this.handleHttpError(err))
      )
      .subscribe();
  }

  private updateUser(): void {
    this.userService.updateUser(this.user)
      .pipe(
        this.scavenger.collectByKey('updateUser'),
        tap(() => {
          this.flashMessageService.show('L\'utilisateur a bien été modifié', { cssClass: 'alert-success'});
          this.router.navigate(['/users']);
        }),
        catchError((err: HttpErrorResponse) => this.handleHttpError(err))
      )
      .subscribe();
  }

  private getForm(): void {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    this.editForm = this.formBuilder.group({
      firstName: new FormControl(this.user.firstName || '', [Validators.required, Validators.minLength(3)]),
      lastName:  new FormControl(this.user.lastName || '', [Validators.required, Validators.minLength(3)]),
      ulgId:     new FormControl(this.user.ulgId || '', [Validators.required, Validators.minLength(5)]),
      email:     new FormControl(this.user.email || '', [Validators.pattern(emailRegex), Validators.required]),
      group:     new FormControl(this.user.group.id, Validators.required)
    });
  }

  private getGroupById(id: number): Group {
    for (const group of this.groups) {
      if (group.id === id) {
         return group;
      }
    }
  }

  private handleError(errors: string[], title = 'Erreur', dismissOnTimeout = true): void {
    this.formErrors.messages = [];
    this.formErrors.messages = errors;
    this.formErrors.title = title;
    this.hasError = true;
    if (dismissOnTimeout) {
      setTimeout(() => this.hasError = false, 2000);
    }
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 500) {
      this.flashMessageService.show(errorMessages.HTTP_500_SERVER_ERROR, { cssClass: 'alert-danger'});
      this.router.navigate(['/users']);
    } else if (err.status === 400) {
      const errors = [];
      for (const key of Object.keys(err.error)) {
        errors.push(`${key} : ${err.error[key]}`);
      }
      this.handleError(errors, 'Erreur',  false);
    } else {
      this.handleError([errorMessages.getErrors(404)], 'Erreur', false);
    }
    return of(null);
  }
}
