import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppRoutingModule } from './app-routing.module';
import { AlertModule, BsDropdownModule, CollapseModule, PaginationModule } from 'ngx-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SecureComponent } from './views/layouts/secure/secure.component';
import { GuardComponent } from './core/guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlashMessagesModule } from 'angular2-flash-messages';

import { CasInterceptor, DebugInterceptor } from './core/interceptors';
import { ColumnSortModule } from './shared/components/column-sort/column-sort.module';
import { SearchBarModule } from './shared/components/search-bar/search-bar.module';
import { AppComponent } from './app.component';
import { UserComponent } from './views/pages/secure/user/user/user.component';
import { UserEditComponent } from './views/pages/secure/user/user-edit/user-edit.component';
import { UserDeleteComponent } from './views/pages/secure/user/user-delete/user-delete.component';
import { GroupComponent } from './views/pages/secure/group/group/group.component';
import { GroupEditComponent } from './views/pages/secure/group/group-edit/group-edit.component';
import { GroupDeleteComponent } from './views/pages/secure/group/group-delete/group-delete.component';
import { HomeComponent } from './views/pages/secure/home/home.component';
import { Page404Component } from './views/pages/errors/page404/page404.component';
import { Page403Component } from './views/pages/errors/page403/page403.component';
import { Page500Component } from './views/pages/errors/page500/page500.component';
import { Page503Component } from './views/pages/errors/page503/page503.component';




@NgModule({
  declarations: [
    AppComponent,
    SecureComponent,
    UserComponent,
    GroupComponent,
    UserEditComponent,
    UserDeleteComponent,
    GroupEditComponent,
    GroupDeleteComponent,
    HomeComponent,
    Page404Component,
    Page403Component,
    Page500Component,
    Page503Component,
  ],
  imports: [
    ColumnSortModule,
    SearchBarModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    FlashMessagesModule.forRoot(),
    AlertModule.forRoot(),
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    AngularFontAwesomeModule
  ],
  providers: [
    GuardComponent,
    { provide: HTTP_INTERCEPTORS, useClass: CasInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DebugInterceptor,  multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
