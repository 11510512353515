import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Scavenger } from '@wishtack/rx-scavenger';

import { Page, User } from '../../../../../shared/models';
import { UserService } from '../../../../../core/http';
import { ColumnSortEvent } from '../../../../../shared/components/column-sort/column-sort-event.model';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  private scavenger = new Scavenger(this);
  isReady = false;
  isLoading = false;
  searchValue = '';
  sort: { field: string, order: number } = { field: null, order: 0 };
  page = new Page(20, 1, 0);
  changingValue: Subject<string> = new Subject();
  users: User[];

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    this.isLoading = true;
    this.userService.getUsers(this.searchValue, this.page, this.sort.field, this.sort.order)
      .pipe(
        this.scavenger.collect(),
        tap((result: { users: User[], totalElements: number }) => {
          this.page.totalElements = result.totalElements;
          this.users = result.users;
          this.isReady = true;
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  handlePageChanged($event: PageChangedEvent): void {
    this.page.pageNumber = $event.page;
    this.loadUsers();
  }

  handleSearch(value: string): void {
    this.searchValue = value;
    this.loadUsers();
  }

  handleOrderChange($event: ColumnSortEvent): void {
    this.sort = { order: $event.order, field: $event.field };
    this.loadUsers();
  }

  handlePageSizeChange(value): void {
    this.page.size = value;
    this.loadUsers();
  }

  identify(index, user: User): number {
    return user.id;
  }

  ngOnDestroy(): void { }
}
