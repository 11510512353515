import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { ColumnSortEvent } from './column-sort-event.model';


@Component({
  selector: 'app-column-sort',
  templateUrl: './column-sort.component.html',
  styleUrls: ['./column-sort.component.scss']
})
export class ColumnSortComponent  implements OnDestroy, OnInit {
  @Input() displayedField: string;
  @Input() expectedField: string;
  @Input() sortClasses: string[] = ['fa fa-sort', 'fa fa-sort-up', 'fa fa-sort-down'];
  @Input() order = 0;
  @Input() currentField: Subject<string>;

  @Output() orderChanged: EventEmitter<ColumnSortEvent> = new EventEmitter();

  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.currentField.subscribe(field => this.expectedField && field && this.expectedField.trim() === field.trim()
      ? this.handleOrder(field)
      : this.order = 0
    );
  }

  /** Handle the order and emit an event */
  public handleOrder(field): void {
    this.order = this.order === 0 ?  1 : (this.order === 1 ? -1 : (0));
    this.orderChanged.emit({ field,  order: this.order });
  }

  /** Handle sort classes according to the order */
  handleSortClasses(): string {
    return this.order === 0
      ? this.sortClasses[0]
      : this.order === 1
        ? this.sortClasses[1]
        : this.sortClasses[2];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
