import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements AfterViewInit,  OnDestroy {
  @ViewChild('bar', {static: false}) searchBar: ElementRef;

  @Input() placeholder = 'Search ...';
  @Input() inputClasses = 'form-control';
  @Input() isLoading = false;
  @Input() debounceTime = 200;
  @Input() searchValue = '';

  @Output() inputChanged: EventEmitter<string> = new EventEmitter();

  private subscription: Subscription;

  /** Handle input event on search */
  ngAfterViewInit(): void {
    const item = this.searchBar.nativeElement;
    item.focus();
    this.subscription = fromEvent(item, 'input')
      .pipe(
        map((i: any) => i.target.value),
        debounceTime(this.debounceTime),
      ).subscribe((val: string) => {
        this.searchValue = val;
        this.notifyParent();
      });
  }

  reset(): void {
    this.searchValue = '';
    this.notifyParent();
  }

  notifyParent(): void {
    this.inputChanged.emit(this.searchValue.trim());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
